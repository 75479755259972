<template>
  <div id="case">
    <div class="content">
      <div class="tips">温馨提示</div>
      <div class="title">{{ title }}</div>
      <div class="btn">
        <el-button class="cancel" @click="clickCancel">取消</el-button>
        <el-button @click="clickConfirm">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: "确认要删除该条数据吗？",
    },
  },
  methods: {
    // 取消
    clickCancel() {
      this.$emit("btnCancel");
    },
    clickConfirm() {
      this.$emit("btnConfirm");
    },
  },
};
</script>

<style lang="less" scoped>
#case {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.2);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    padding: 20px 30px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--black);
    .tips {
      font-size: var(--mainSize);
      text-align: center;
    }
    .title {
      font-size: var(--subSize);
      text-align: center;
      margin: 16px 0;
      color: #888888;
    }
    .btn {
      width: 100%;
      display: flex;
      justify-content: center;
      .el-button {
        padding: 8px 20px;
        color: var(--white);
        font-size: var(--subSize);
        background: var(--pink);
      }
      .cancel {
        background: var(--info);
        margin-right: 30px;
      }
    }
  }
}
</style>