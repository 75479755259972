<template>
  <div id="myHeader">
    <div class="myLeft">
      <img class="logo" src="../../assets/img/header/logo.png" />
      <div class="admin_text">海外华人征婚网后台运营系统</div>
    </div>
    <div class="myRight">
      <img class="data_img" src="../../assets/img/header/data.png" alt="" />
      <div class="time">{{ time }}</div>
      <div>欢迎您，</div>
      <div class="handlers" @click="clickInformation">
        {{ handlers.username }}
      </div>
      <img
        class="close_red"
        src="../../assets/img/header/close_red.png"
        alt=""
      />
      <div class="exit" @click="handleExit">退出</div>
    </div>
    <!-- 更改信息 -->
    <my-amend v-show="isInformation" @btnClose="isInformation = false">
      <div class="isTitle">更改资料</div>
      <el-form
        :model="infoForm"
        label-width="100px"
        :rules="infoRules"
        ref="infoForm"
      >
        <el-form-item label="原密码：" prop="pass">
          <el-input
            type="password"
            v-model="infoForm.pass"
            placeholder="请输入原密码"
            :maxlength="18"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPass">
          <el-input
            type="password"
            v-model="infoForm.newPass"
            placeholder="请输入新密码"
            :maxlength="18"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="checkPass">
          <el-input
            type="password"
            v-model="infoForm.checkPass"
            placeholder="请再次输入新密码"
            :maxlength="18"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="checkPhone">
          <el-input
            v-model="infoForm.checkPhone"
            placeholder="请输入新手机号码"
            :maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="checkCode">
          <div class="code">
            <el-input
              v-model="infoForm.checkCode"
              :maxlength="4"
              placeholder="请输入验证码"
            ></el-input>
            <div class="authCode" @click="getInfoCode">
              {{ authCode }}
            </div>
          </div>
        </el-form-item>
        <div class="btn">
          <el-button @click="submitAffirm('infoForm')">保存</el-button>
        </div>
      </el-form>
    </my-amend>
    <my-case
      v-show="isCase"
      @btnCancel="isCase = false"
      @btnConfirm="btnConfirm"
      title="确认退出当前账号？"
    ></my-case>
  </div>
</template>

<script>
import myCase from "../case/case.vue";
import myAmend from "../case/amend.vue";

import { getCode } from "../code/code";
import { request } from "../../API/request";
export default {
  name: "myHeader",
  components: {
    myCase,
    myAmend,
  },
  data() {
    let checkPassword = (rule, value, callback) => {
      if (value != this.infoForm.newPass) {
        callback(new Error());
      } else {
        callback();
      }
    };
    let CheckCode = (rule, value, callback) => {
      if (value != this.authCode) {
        callback(new Error());
      } else {
        callback();
      }
    };
    return {
      isCase: false, // 退出弹框
      isInformation: false, // 个人资料弹框
      infoForm: {
        pass: "",
        newPass: "",
        checkPass: "",
        checkPhone: "",
        checkCode: "",
      },
      infoRules: {
        pass: [
          { required: true, message: "原密码不能为空！", trigger: "blur" },
        ],
        newPass: [
          { required: true, message: "新密码不能为空！", trigger: "blur" },
          {
            min: 8,
            max: 18,
            pattern:
              /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,18}$/,
            message: "密码只能能包含数字、英文、字符中的两种以上，长度6 - 18",
            trigger: "blur",
          },
        ],
        checkPass: [
          { required: true, message: "确认密码不能为空！", trigger: "blur" },
          {
            validator: checkPassword,
            message: "两次输入的密码不一致，请检查！",
            trigger: "blur",
          },
        ],
        checkPhone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur",
          },
        ],
        checkCode: [
          { required: true, message: "验证码不能为空!", trigger: "blur" },
          {
            validator: CheckCode,
            message: "验证码输入不一致！",
            trigger: "blur",
          },
        ],
      }, // 个人资料校检
      authCode: "", // 验证码
      time: "", // 时间
      handlers: JSON.parse(localStorage.getItem("userInfo")), // 操作人员
    };
  },
  created() {
    this.setTime();
  },
  methods: {
    // 获取时间
    setTime() {
      let that = this;
      this.timer = setInterval(
        (function setTime() {
          let d = new Date();
          let data =
            d.getFullYear() +
            "-" +
            (d.getMonth() < 10 ? "0" + (d.getMonth() + 1) : d.getMonth()) +
            "-" +
            d.getDate() +
            " " +
            d.getHours() +
            ":" +
            (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());
          that.time = data;
          return setTime;
        })(),
        1000
      );
    },
    clickInformation() {
      this.getInfoCode();
      this.isInformation = true;
    },
    // 重置验证码
    getInfoCode() {
      this.authCode = getCode();
    },
    // 退出弹框
    handleExit() {
      this.isCase = true;
    },
    // 确定退出登录
    btnConfirm() {
      localStorage.removeItem("userInfo");
      this.$router.push("/login");
    },
    // 提交信息
    submitAffirm(infoForm) {
      this.$refs[infoForm].validate((valid) => {
        if (valid) {
          request({
            url: "/user/update",
            method: "POST",
            data: {
              password: this.infoForm.newPass,
              phone: this.infoForm.checkPhone,
              pwd: this.infoForm.pass,
              uid: this.handlers.uid,
            },
          }).then((res) => {
            if (res.code === 200) {
              let dataInfo = res.data;
              dataInfo.Authorization = this.handlers.Authorization;
              localStorage.setItem("userInfo", JSON.stringify(dataInfo));
              this.$message.success("操作成功！");
              this.isInformation = false;
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error("信息填写有误，请检查！");
          return false;
        }
      });
    },
  },
  // 页面销毁清除定时器
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
#myHeader {
  height: 60px;
  width: 100%;
  background: var(--pink);
  display: flex;
  font-size: var(--subSize);
  color: var(--white);
  .myLeft {
    display: flex;
    align-items: center;
    margin-left: 30px;
    min-width: 440px;
    .logo {
      width: 60px;
      height: 60px;
    }
    .admin_text {
      font-size: var(--bigSize);
    }
  }
  .myRight {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 460px;
    .data_img {
      // margin-left: 700px;
      width: 25px;
      height: 23px;
    }
    .time {
      margin: 0 50px 0 12px;
    }
    .handlers {
      color: var(--subPink);
      cursor: pointer;
    }
    .close_red {
      width: 24px;
      height: 25px;
      margin: 0 8px 0 30px;
    }
    .exit {
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .isTitle {
    width: 100%;
    text-align: center;
    font-size: var(--bigSize);
    margin-bottom: 20px;
  }
  .el-form {
    .el-form-item {
      /deep/.el-form-item__label {
        text-align: start;
      }
      .code {
        position: relative;
        .authCode {
          position: absolute;
          top: 10%;
          right: 0;
          height: 80%;
          line-height: 1;
          width: 30%;
          cursor: pointer;
          z-index: 99999;
          text-align: center;
          font-weight: bold;
          font-size: 30px;
          color: var(--pink);
        }
      }
    }
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .el-button {
    margin: 23px 0;
    width: 30%;
    background: var(--pink);
    color: var(--white);
    font-size: var(--minSize);
  }
}
</style>