<template>
  <el-container>
    <el-aside width="200px">
      <sidebar></sidebar>
    </el-aside>
    <el-container>
      <el-header height="60px">
        <my-header></my-header>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import myHeader from "../components/common/myHeader";
import sidebar from "../components/common/sidebar";

export default {
  components: {
    myHeader,
    sidebar,
  },
};
</script>

<style scoped>
.el-aside {
  height: 100vh;
  background-color: var(--pink);
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 12px;
  height: calc(100vh - 60px);
  background-color: #f7f8fa;
}
</style>