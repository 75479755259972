<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      text-color="#ffffff"
      active-text-color="#ffffff"
      unique-opened
      router
    >
      <div class="menus">
        <img
          class="meau_icon"
          src="../../assets/img/sidebar/meau_icon.png"
          alt=""
        />
        <p>菜单栏</p>
      </div>
      <!-- 一级菜单 -->
      <template v-for="item in sideList">
        <template v-if="item.children">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.children">
              <el-submenu
                v-if="subItem.children"
                :index="subItem.index"
                :key="subItem.index"
              >
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.children"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  data() {
    return {
      sideList: [
        {
          index: "data",
          title: "数据管理",
          children: [
            {
              index: "daily",
              title: "日报",
            },
            {
              index: "weekly",
              title: "周报",
            },
            {
              index: "monthly",
              title: "月报",
            },
          ],
        },
        {
          index: "xiaocx",
          title: "小程序",
          children: [
            {
              index: "appUser",
              title: "编辑小程序用户信息",
            },
            {
              index: "usercheck",
              title: "个人资料审核",
            },
            {
              index: "requese",
              title: "择偶标准审核",
            },
            {
              index: "myHeart",
              title: "内心独白审核",
            },
            {
              index: "emotion",
              title: "情感经历审核",
            },
            {
              index: "personalPhoto",
              title: "个人照片审核",
            },
            {
              index: "dynamicCheck",
              title: "动态审核",
            }
          ],
        },
        {
          index: "invitation",
          title: "邀请好友",
        },
        {
          index: "topic",
          title: "话题管理",
        },
        {
          index: "sensitive",
          title: "敏感词",
        },
        {
          index: "business",
          title: "业务管理",
          children: [
            {
              index: "filterBusiness",
              title: "筛选业务",
            },
            {
              index: "wait",
              title: "观望",
            },
            {
              index: "purpose",
              title: "意向",
            },
            {
              index: "chengjiao",
              title: "成交",
            },
            {
              index: "quit",
              title: "退出",
            },
            {
              index: "check",
              title: "待核实",
            }
          ],
        },
        {
          index: "member",
          title: "会员管理",
        },
        {
          index: "newMember",
          title: "新会员管理",
        },
        {
          index: "account",
          title: "账号管理",
        },
        {
          index: "log",
          title: "操作日志",
        },
        {
          index: "remind",
          title: "新手指引",
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  height: 100vh;
  overflow-y: scroll;
  .menus {
    display: flex;
    margin-left: 20px;
    align-items: center;
    color: var(--white);
    font-size: var(--subSize);
    .meau_icon {
      width: 30px;
      height: 23px;
      margin-right: 12px;
    }
  }
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar > ul {
  height: 100%;
}
/deep/.el-menu {
  background-color: var(--pink);
  .is-active {
    background-color: var(--subPink);
  }
  .el-menu-item {
    min-width: 200px;
    &:hover {
      background-color: var(--subPink);
    }
  }
}
.el-submenu {
  min-width: 200px;
  /deep/.el-submenu__title:hover {
    background-color: var(--subPink);
  }
}
.el-menu /deep/.el-submenu__icon-arrow {
  color: #fff;
}
</style>